import gql from 'graphql-tag';

export const GET_CUSTOMERS = gql`
  query Users(
    $searchText: String
    $userType: UserType
    $limit: Float = 0
    $branchId: String
  ) {
    users(
      searchText: $searchText
      userType: $userType
      limit: $limit
      branchId: $branchId
    ) {
      id
      pwd
      username
      userType
      nameKanji
      tel
      nameKana
      addresses {
        id
        address
        default
        lastUsed
      }
      sellerId
      sellerId1
      sellerId2
      sellerId3
      gender
      jobTitle
      visitorFlagDay1
      visitorFlagDay2
      sellerId4
      companyNameKanji
      companyNameKana
      mailAddr
      hatTeamCode
      hatTokuCode
    }
  }
`;
export const GET_USERS = gql`
  query Users1(
    $alanyticsCount: Boolean = true
    $userType: UserType
    $searchText: String
    $limit: Float = 0
  ) {
    users(
      alanyticsCount: $alanyticsCount
      userType: $userType
      searchText: $searchText
      limit: $limit
    ) {
      id
      pwd
      username
      userType
      nameKanji
      tel
      nameKana
      point
      analyticsCount
      addresses {
        id
        address
        default
        lastUsed
      }
      sellerId
      mailAddr
    }
  }
`;

export const GET_USERS_HANBAITEN = gql`
  query Users2($userType: UserType) {
    users(limit: 0, userType: $userType) {
      id
      username
      userType
      nameKanji
      hatTeamCode
      hatTokuCode
      sellerId
      companyNameKanji
      sendMail
      addresses {
        id
        address
        default
        lastUsed
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query {
    profile {
      username
      point
      hatTokuCode
      userType
    }
  }
`;

export const GET_COMPANY_AND_COUNT = gql`
  query ($limit: Float = 33000) {
    companyAndCount(limit: $limit) {
      companies {
        id
        name
      }
    }
  }
`;

export const GET_TOTAL_NUM_COMPANY = gql`
  query companyAndCount {
    companyAndCount {
      count
    }
  }
`;

export const GET_COMPANY_AND_COUNT_WITH_OFFSET = gql`
  query companyAndCount(
    $offset: Float = 0
    $limit: Float = 20
    $searchText: String = ""
  ) {
    companyAndCount(offset: $offset, limit: $limit, searchText: $searchText) {
      companies {
        id
        name
      }
      count
    }
  }
`;

export const GET_POINTS = gql`
  query ($userId: String, $offset: Float = 0, $limit: Float = 10) {
    points(userId: $userId, offset: $offset, limit: $limit) {
      id
      user {
        id
        username
      }
      event
      orderId
      productId
      productName
      gift
      changeById
      changeByName
      serialCode
      amount
      createdAt
    }
  }
`;

export const GET_ANALYTICS_EVENT_AND_COUNT = gql`
  query ($userId: String!) {
    analyticsEventAndCount(userId: $userId) {
      event
      count
    }
  }
`;

export const GET_ANALYTICS = gql`
  query (
    $userId: String
    $offset: Float = 0
    $limit: Float = 20
    $event: String
  ) {
    analytics(userId: $userId, offset: $offset, limit: $limit, event: $event) {
      id
      event
      data
      createdAt
    }
  }
`;

export const USERS_WITH_COUNT = gql`
  query (
    $userType: UserType
    $orderBy: UserOrderByInput
    $offset: Float = 0
    $limit: Float = 0
    $analyticsCount: Boolean
    $sellerId: String
    $searchText: String
    $branchId: String
    $teamCode: String
    $sellerInfo: Boolean = true
    $isReceiveMail: Boolean
    $userOpsFlagType: UserOpsFlagType
    $orderByKeys: UserOrderByKeyInput = {
      fields: [BRANCH_CODE, TEAM_CODE, HAT_TOKU_CODE, KEYMAN_CODE]
      direction: ASC
    }
  ) {
    usersAndCount(
      userType: $userType
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      searchText: $searchText
      branchId: $branchId
      teamCode: $teamCode
      alanyticsCount: $analyticsCount
      sellerId: $sellerId
      sellerInfo: $sellerInfo
      isReceiveMail: $isReceiveMail
      userOpsFlagType: $userOpsFlagType
      orderByKeys: $orderByKeys
    ) {
      count
      users {
        id
        pwd
        userId
        username
        userType
        nameKanji
        tel
        nameKana
        analyticsCount
        jobTitle
        visitorFlagDay1
        visitorFlagDay2
        gender
        addresses {
          id
          address
          default
          lastUsed
        }
        isReceiveMail
        sellerId
        sellerName
        companyNameKanji
        companyNameKana
        mailAddr
        hatTeamCode
        hatTokuCode
        hatTokuCodeName
        keyManName
        keyManCode
        branch {
          id
          name
        }
        sellerId1
        sellerId2
        sellerId3
        sellerId4
        seller1 {
          id
          keymanCode
          keymanName
        }
        seller2 {
          id
          keymanCode
          keymanName
        }
        seller3 {
          id
          keymanCode
          keymanName
        }
        seller4 {
          id
          keymanCode
          keymanName
        }
      }
    }
  }
`;

export const USERS_WITH_COUNT_POINT = gql`
  query (
    $userType: UserType
    $orderBy: UserOrderByInput = { field: POINT, direction: DESC }
    $offset: Float = 0
    $limit: Float = 0
    $analyticsCount: Boolean
    $sellerId: String
    $searchText: String
    $branchId: String
    $sellerInfo: Boolean = true
  ) {
    usersAndCount(
      userType: $userType
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      searchText: $searchText
      branchId: $branchId
      alanyticsCount: $analyticsCount
      sellerId: $sellerId
      sellerInfo: $sellerInfo
    ) {
      count
      users {
        id
        pwd
        username
        userType
        nameKanji
        point
        tel
        nameKana
        sellerId
        companyNameKanji
        companyNameKana
        mailAddr
        hatTeamCode
        hatTokuCode
      }
    }
  }
`;
export const USERS_WITH_COUNT_ANALYTICS = gql`
  query (
    $userType: UserType
    $orderBy: UserOrderByInput = { field: ANALYTICS_COUNT, direction: DESC }
    $offset: Float = 0
    $limit: Float = 0
    $analyticsCount: Boolean = true
    $sellerId: String
    $searchText: String
    $branchId: String
    $sellerInfo: Boolean = true
  ) {
    usersAndCount(
      userType: $userType
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      searchText: $searchText
      branchId: $branchId
      alanyticsCount: $analyticsCount
      sellerId: $sellerId
      sellerInfo: $sellerInfo
    ) {
      count
      users {
        id
        pwd
        username
        userType
        nameKanji
        point
        tel
        nameKana
        sellerId
        companyNameKanji
        companyNameKana
        mailAddr
        hatTeamCode
        hatTokuCode
        analyticsCount
      }
    }
  }
`;
