import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Button, Pagination } from 'antd';
import { withStyle } from 'baseui';
import React, { useEffect, useState } from 'react';
import Checkbox from '../../components/CheckBox/CheckBox';
import { Col as Column, Row as Rows } from '../../components/FlexBox/FlexBox';
import NoResult from '../../components/NoResult/NoResult';
import openNotification, {
  typeNotificaton,
} from '../../components/openNotification/openNotification';
import Select from '../../components/Select/Select';
import { Header, Heading, Wrapper } from '../../components/WrapperStyle';
import { UPDATE_USER } from '../../graphql/mutation/usertokyo.mutation';
import {
  GET_COMPANY_BY_TEAMCODE,
  GET_TEAM_CODE,
} from '../../graphql/query/company.query';
import { USERS_WITH_COUNT } from '../../graphql/query/usertokyo.query';
import { UserType } from '../../models';
import {
  StyledCell,
  StyledHead,
  StyledHeadCell,
  StyledRow,
  StyledTable,
  StyledTBody,
} from '../Category/Category.style';
import { theme } from '../../theme';

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));
const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));

const userTypeSelect = {
  kojiten: 'kojiten',
  hanbaiten: 'hanbaiten',
};
function VisitorSetting() {
  const classes = useStyles();

  const [companies, setCompanies] = useState([]);
  const [loadingManual, setLoadingManual] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [userType, setUserType] = React.useState('kojiten');
  const { data: hatTeamCodes, loading: loadingTeamCode } =
    useQuery(GET_TEAM_CODE);
  const {
    data: usersKojiten,
    refetch: refetchKojiten,
    loading,
  } = useQuery(USERS_WITH_COUNT, { skip: true });

  const { data: companyByTeamCode, refetch: fetchCompany } = useQuery(
    GET_COMPANY_BY_TEAMCODE,
    {
      skip: true,
    }
  );
  const [updateUser] = useMutation(UPDATE_USER);

  const [teamCodeChosen, setTeamCodeChosen] = useState({
    value: '',
    label: '',
  });
  const [companyChosen, setCompanyChosen] = useState({
    value: '',
    label: '',
  });
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataCount, setDataCount] = useState([]);
  const PAGE_SIZE = 20;

  const handleUserTypeChange = (event) => {
    const { value } = event.target;
    setUserType(value);
    setDataList([]);
    setDataCount([]);
    setTeamCodeChosen({
      value: '',
      label: '',
    });
    setCompanyChosen({
      value: '',
      label: '',
    });
  };

  useEffect(() => {
    const dataList = dataCount?.slice(
      (currentPage - 1) * PAGE_SIZE,
      (currentPage - 1) * PAGE_SIZE + PAGE_SIZE
    );
    setDataList(dataList);
  }, [currentPage]);

  const handleChooseCompany = (e) => {
    setCompanyChosen(e.option);
    setLoadingManual(true);
    setLoadingData(true);
    refetchKojiten({
      userType: userType,
      searchText: e.option.value,
    }).then(({ data }) => {
      const filtered = data?.usersAndCount?.users
        // .filter((item) => {
        //   return item.hatTokuCode === e.option.value
        // })
        .filter((item) => {
          return item.hatTeamCode === teamCodeChosen.value;
        });
      setDataList(filtered);
      setLoadingManual(false);
      setLoadingData(false);
    });
  };

  const handlePagination = (e) => {
    setCurrentPage(e);
    const headerTable = document.querySelectorAll('#toprow');
    if (headerTable) {
      headerTable[0]?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleChooseTeamCode = (e) => {
    setTeamCodeChosen(e.option);
    setCompanyChosen({
      value: '',
      label: '',
    });
    setLoadingManual(true);
    if (userType === 'kojiten') {
      setLoadingData(true);
      refetchKojiten({
        userType: userType,
        searchText: e.option.value,
      }).then(({ data }) => {
        const dataCount = data?.usersAndCount?.users.filter((item) => {
          return item.hatTeamCode === e.option.value;
        });
        const filtered = dataCount?.slice(
          (currentPage - 1) * PAGE_SIZE,
          (currentPage - 1) * PAGE_SIZE + PAGE_SIZE
        );
        if (filtered?.length === 0) {
          setDataList(dataCount);
        } else {
          setDataList(filtered);
        }
        setDataCount(dataCount);
        setLoadingData(false);
      });
      fetchCompany({
        teamCode: e.option.value,
      }).then(({ data }) => {
        const companyList = data?.hatTokuCodesAndCompanies?.map((company) => ({
          label: `${company.companyName}(${company.hatTokuCode})`,
          value: company.hatTokuCode,
        }));
        setCompanies(companyList);
        setLoadingManual(false);
      });
    } else {
      setDataList([]);
      fetchCompany({
        teamCode: e.option.value,
      }).then(({ data }) => {
        const companyList = data?.hatTokuCodesAndCompanies?.map((company) => ({
          label: `${company.companyName}(${company.hatTokuCode})`,
          value: company.hatTokuCode,
        }));
        setCompanies(companyList);
        setLoadingManual(false);
      });
    }
  };
  const handlevisitorDay1Flag = (event, index) => {
    const userId = dataList[index];
    const newData = {
      visitorFlagDay1: !userId.visitorFlagDay1,
    };
    updateUser({
      variables: {
        id: userId.id,
        user: newData,
      },
    })
      .then(() => {
        openNotification(
          typeNotificaton.success,
          'ユーザの来場者設定が更新されました。'
        );
        const newDataList = [...dataList];
        newDataList[index].visitorFlagDay1 =
          !newDataList[index].visitorFlagDay1;
        setDataList(newDataList);
      })
      .catch((err) => {
        openNotification(
          typeNotificaton.error,
          `${err.toString().split(' ').slice(2).join('')}`
        );
      });
  };
  const handlevisitorDay2Flag = (event, index) => {
    const userId = dataList[index];
    const newData = {
      visitorFlagDay2: !userId.visitorFlagDay2,
    };
    updateUser({
      variables: {
        id: userId.id,
        user: newData,
      },
    })
      .then(() => {
        openNotification(
          typeNotificaton.success,
          'ユーザの来場者設定が更新されました。'
        );
        const newDataList = [...dataList];
        newDataList[index].visitorFlagDay2 =
          !newDataList[index].visitorFlagDay2;
        setDataList(newDataList);
      })
      .catch((err) => {
        openNotification(
          typeNotificaton.error,
          `${err.toString().split(' ').slice(2).join('')}`
        );
      });
  };

  const checkHasData = () => {
    if (dataList.length > 0) {
      return true;
    } else {
      if (userType === 'kojiten') {
        return teamCodeChosen.value.length === 0 ? false : true;
      }
      if (userType === 'hanbaiten') {
        return companyChosen.value.length === 0 ? false : true;
      }
    }
  };

  return (
    <>
      <Header
        style={{
          marginBottom: 15,
        }}
      >
        <Col xl={4} lg={3} md={2} xs={12}>
          <Row>
            <Col xl={4} lg={7} md={8} xs={12}>
              <Heading>来場者設定</Heading>
            </Col>
            <Col xl={4} lg={5} md={4} xs={12}>
              <a href='https://smart.mirai-ichi.com/print' target='_blank'>
                <Button
                  style={{ color: theme.colors.primary, fontWeight: 'bold' }}
                  // onClick={() => openDrawer(row)}
                >
                  名札一括印刷へ
                </Button>
              </a>
            </Col>
          </Row>
        </Col>
        <Col xl={8} lg={9} md={10} xs={12}>
          <Row>
            <Col xl={6} lg={6} md={6} xs={12}>
              <RadioGroup
                className={classes.root}
                row
                aria-label='gender'
                name='gender1'
                value={userType}
                onChange={handleUserTypeChange}
              >
                <FormControlLabel
                  value='kojiten'
                  control={<Radio size='small' />}
                  label='工事店'
                />
                <FormControlLabel
                  value='hanbaiten'
                  control={<Radio size='small' />}
                  label='販売店'
                />
              </RadioGroup>
            </Col>
            <Col xl={3} lg={3} md={3} xs={12}>
              <Select
                options={hatTeamCodes?.hatTeamCodes
                  .filter((code) => !!code && code !== 'undefined')
                  .map((code) => ({
                    label: code,
                    value: code,
                  }))}
                labelKey='label'
                valueKey='value'
                value={teamCodeChosen}
                onChange={handleChooseTeamCode}
                clearable={false}
                searchable={true}
                placeholder={
                  teamCodeChosen.value.length > 0 ? '' : 'チームコードの選択'
                }
                disabled={loadingData || loadingTeamCode}
                isLoading={loadingData || loadingTeamCode}
                overrides={{
                  Dropdown: {
                    style: ({ $theme }) => {
                      return {
                        maxHeight: '40vh',
                      };
                    },
                  },
                  Placeholder: {
                    style: ({ $theme }) => {
                      return {
                        ...$theme.typography.fontBold14,
                        color: $theme.colors.textNormal,
                      };
                    },
                  },
                  DropdownListItem: {
                    style: ({ $theme }) => {
                      return {
                        ...$theme.typography.fontBold14,
                        color: $theme.colors.textNormal,
                      };
                    },
                  },
                  OptionContent: {
                    style: ({ $theme, $selected }) => {
                      return {
                        ...$theme.typography.fontBold14,
                        color: $selected
                          ? $theme.colors.textDark
                          : $theme.colors.textNormal,
                      };
                    },
                  },
                  SingleValue: {
                    style: ({ $theme }) => {
                      return {
                        ...$theme.typography.fontBold14,
                        color: $theme.colors.textDark,
                      };
                    },
                  },
                  Popover: {
                    props: {
                      overrides: {
                        Body: {
                          style: { zIndex: 5 },
                        },
                      },
                    },
                  },
                }}
              />
            </Col>
            {userType === UserType.hanbaiten && (
              <Col xl={3} lg={3} md={3} xs={12}>
                <Select
                  options={companies}
                  labelKey='label'
                  valueKey='value'
                  clearable={false}
                  searchable={true}
                  value={companyChosen}
                  disabled={
                    teamCodeChosen.value.length === 0 ||
                    loadingManual ||
                    loadingData
                  }
                  isLoading={loadingManual || loadingData}
                  placeholder={
                    companyChosen.value.length > 0 ? '' : '会社名の選択'
                  }
                  onChange={handleChooseCompany}
                  overrides={{
                    Dropdown: {
                      style: ({ $theme }) => {
                        return {
                          maxHeight: '40vh',
                        };
                      },
                    },
                    DropdownContainer: {
                      style: ({ $theme }) => ({
                        width: '25vw',
                      }),
                    },
                    Placeholder: {
                      style: ({ $theme }) => {
                        return {
                          ...$theme.typography.fontBold14,
                          color: $theme.colors.textNormal,
                        };
                      },
                    },
                    DropdownListItem: {
                      style: ({ $theme }) => {
                        return {
                          ...$theme.typography.fontBold14,
                          color: $theme.colors.textNormal,
                        };
                      },
                    },
                    OptionContent: {
                      style: ({ $theme, $selected }) => {
                        return {
                          ...$theme.typography.fontBold14,
                          color: $selected
                            ? $theme.colors.textDark
                            : $theme.colors.textNormal,
                        };
                      },
                    },
                    SingleValue: {
                      style: ({ $theme }) => {
                        return {
                          ...$theme.typography.fontBold14,
                          color: $theme.colors.textDark,
                        };
                      },
                    },
                    Popover: {
                      props: {
                        overrides: {
                          Body: {
                            style: { zIndex: 5 },
                          },
                        },
                      },
                    },
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Header>
      <Wrapper
        style={{
          boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)',
          overflow: 'hidden',
        }}
      >
        <StyledTable>
          <StyledHead
            style={{
              display: 'block',
            }}
          >
            <StyledHeadCell
              style={{
                width: '15%',
                minWidth: '150px',
              }}
            >
              ユーザID
            </StyledHeadCell>
            <StyledHeadCell
              style={{
                width: '15%',
                minWidth: '150px',
              }}
            >
              ログインID
            </StyledHeadCell>
            <StyledHeadCell
              style={{
                width: '15%',
                minWidth: '150px',
              }}
            >
              ユーザ名
            </StyledHeadCell>
            <StyledHeadCell
              style={{
                width: '20%',
                minWidth: '150px',
              }}
            >
              会社名
            </StyledHeadCell>
            {userType === 'kojiten' ? (
              <StyledHeadCell
                style={{
                  width: '20%',
                  minWidth: '150px',
                }}
              >
                ご紹介販売店様
              </StyledHeadCell>
            ) : (
              ''
            )}
            <StyledHeadCell
              style={{
                width: '1%',
                minWidth: '100px',
              }}
            >
              10/13(金)
            </StyledHeadCell>
            <StyledHeadCell
              style={{
                width: '1%',
                minWidth: '100px',
              }}
            >
              10/14(土)
            </StyledHeadCell>
          </StyledHead>
          <StyledTBody
            style={{
              display: 'block',
              overflow: 'auto',
            }}
          >
            {dataList?.length !== 0 ? (
              dataList.map((row, index) => (
                <React.Fragment key={index}>
                  <StyledRow id='toprow'>
                    <StyledCell
                      style={{
                        width: '15%',
                        minWidth: '150px',
                      }}
                    >
                      {row.userId}
                    </StyledCell>
                    <StyledCell
                      style={{
                        width: '15%',
                        minWidth: '150px',
                      }}
                    >
                      {row.username}
                    </StyledCell>
                    <StyledCell
                      style={{
                        width: '15%',
                        minWidth: '150px',
                      }}
                    >
                      {row.nameKanji}
                    </StyledCell>
                    <StyledCell
                      style={{
                        width: '20%',
                        minWidth: '150px',
                      }}
                    >
                      {row.companyNameKanji ? row.companyNameKanji : 'なし'}
                    </StyledCell>
                    {userType === 'kojiten' ? (
                      <StyledCell
                        style={{
                          width: '20%',
                          minWidth: '150px',
                        }}
                      >
                        {`
                        ${
                          companies.filter(
                            (item) => item.value === row.sellerId
                          ).length > 0
                            ? companies.filter(
                                (item) => item.value === row.sellerId
                              )?.[0]?.label
                            : '見つかりませんでした。'
                        }`}
                      </StyledCell>
                    ) : (
                      ''
                    )}
                    <StyledCell
                      style={{
                        width: '1%',
                        minWidth: '100px',
                      }}
                    >
                      <Checkbox
                        name={row.id}
                        checked={row.visitorFlagDay1}
                        onChange={(event) => {
                          handlevisitorDay1Flag(event, index);
                        }}
                      />
                    </StyledCell>
                    <StyledCell
                      style={{
                        width: '1%',
                        minWidth: '85px',
                      }}
                    >
                      <Checkbox
                        name={row.id}
                        checked={row.visitorFlagDay2}
                        onChange={(event) => {
                          handlevisitorDay2Flag(event, index);
                        }}
                      />
                    </StyledCell>
                  </StyledRow>
                </React.Fragment>
              ))
            ) : loadingData ? (
              <div
                style={{
                  width: '100%',
                  padding: '20px',
                  textAlign: 'center',
                }}
              >
                データ取得中...
              </div>
            ) : !checkHasData() ? (
              <div
                style={{
                  width: '100%',
                  padding: '20px',
                  textAlign: 'center',
                }}
              >
                {`${
                  userType === 'kojiten'
                    ? 'チームコードを選択して下さい。'
                    : 'チームコードと会社名を選択して下さい。'
                }`}
              </div>
            ) : (
              <NoResult
                hideButton={false}
                style={{
                  gridColumnStart: '1',
                  gridColumnEnd: 'one',
                }}
              />
            )}
          </StyledTBody>
        </StyledTable>
        <div
          style={{
            marginBottom: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Pagination
            current={currentPage}
            total={dataCount?.length}
            hideOnSinglePage={true}
            defaultPageSize={PAGE_SIZE}
            size='small'
            onChange={handlePagination}
            simple={true}
          />
        </div>
      </Wrapper>
    </>
  );
}

export default VisitorSetting;

export const useStyles = makeStyles({
  root: {
    display: 'block',
    textAlign: 'right',
  },
});
